<template>
  <div id="app" data-app>
    <div class="row col-xl-12 mx-0 px-0">
      <!--start::TOPFORM-->
      <Topform
        ref="external-topform"
        :managementType="managementType"
        :status="calculatedStatus"
        :pageType="isScreenRecord ? 'screen_record' : 'external'"
        :name="activityInfo.title"
        :errors="activityErrors"
        :submissionStates="submissionStates"
        parentPage="/project/activities/view"
        :tabs="computedTabs"
        :validation="computedValidation"
        :loadingComplete="loadingComplete"
        @on_blur="checkURL()"
        @clear_errors="clearErrors()"
        @asset_reservation_created="form.stimuli.push($event)"
        @asset_reservation_deleted="
          form.stimuli = form.stimuli.filter(function(stim) {
            return stim !== $event;
          })"
        @remove_project_asset="form.stimuli_remove.push($event)"
        @start_date_updated="form.starts_on = $event"
        @end_date_updated="form.ends_on = $event"
        @clear_existing_data="clearData"
        @submit="onSubmit"
      >
      </Topform>
      <!--end::TOPFORM-->
      <div class="col-xl-4">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <b-spinner
              v-if="loadingComplete == false"
              variant="primary"
              label="Spinning"
              class="card-loader"
            >
            </b-spinner>
            <div v-if="loadingComplete && !error.active">
              <h6 class="mb-2">
                {{ $t("ACTIVITY.MANAGE.HELPER_SEGMENT_TITLE") }}
              </h6>
              <p class="mb-10">
                {{ $t("ACTIVITY.MANAGE.HELPER_SEGMENT_TEXT") }}
              </p>
              <div class="row">
                <!-- Here be dragons - submissionStates manually set to false for now -->
                <InputAdvancedSegmentSelect
                  id="input-segments"
                  class="px-5 mb-5"
                  :submissionStates="{ submitting: false }"
                  children_key="project_segments"
                  label_key="name"
                  :options="projectSegmentations"
                  :initialSelection="initial_selection"
                  @children_changed="form.segments = $event"
                  @clear_segments="form.segments = []"
                  @initial_selection_changed="initial_selection = $event"
                >
                </InputAdvancedSegmentSelect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  url,
  required,
  requiredIf
} from "vuelidate/lib/validators";
import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import ManageActivityHelper from "@/helpers/ManageActivityHelper";
import InputAdvancedSegmentSelect from "@/view/components/form-inputs/AdvancedSegmentSelect";
import PageLoader from "@/core/classes/PageLoader.js";
import Toast from "@/core/classes/Toast.js";

//Vuex Imports
import {
  CREATE_PROJECT_ACTIVITY,
  UPDATE_PROJECT_ACTIVITY,
  CLEAR_ACTIVITY_ERRORS,
  POPULATE_ACTIVITY_INFO,
  CLEAR_ACTIVITY_INFO
} from "@/core/services/store/activity/activity.module";
import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";
import { POPULATE_PROJECT_ROOM_SETUP_LIST } from "@/core/services/store/project/project_room_setups.module";

//Topform Imports
import TopformConfig from "@/core/config/topform/ManageExternal.js";

export default {
  mixins: [validationMixin],
  name: "ManageExternal",

  data() {
    return {
      initial_selection: [],
      error: {
        active: false,
        message: ""
      },
      pageLoader: new PageLoader(2),
      toast: new Toast(),
      submissionStates: {
        submitting: false
      },
      form: {
        published: 1,
        featured: 0,
        project_room_id: null,
        starts_on: null,
        ends_on: null,
        system_activity_type_id: 7,
        title: null,
        summary: null,
        link: null,
        popout: 1,
        stimuli: [],
        stimuli_remove: [],
        link_id: null,
        segments: [],
        settings: {
          // Support screen record
          6: {
            data: {
              value: 0
            }
          }
        },
      },
      form_reset: {}
    };
  },

  validations: {
    form: {
      published: {
        required
      },
      featured: {
        required
      },
      project_room_id: {},
      starts_on: {
        required: requiredIf(function() {
          return this.isScheduledActivity;
        }),
        maxValue(val, { ends_on }) {
          return new Date(val) < new Date(ends_on);
        }
      },
      ends_on: {
        required
      },
      system_activity_type_id: {
        required
      },
      title: {
        required
      },
      summary: {},
      link: {
        url,
        required
      },
      popout: {
        required
      },
      stimuli: {},
      stimuli_remove: {},
      segments: {},
      settings: {
        6: {
          data: {
            value: {
              required
            }
          }
        }
      }
    }
  },

  components: {
    InputAdvancedSegmentSelect
  },

  mounted() {
    this.clearErrors();
    this.form_reset = JSON.parse(JSON.stringify(this.form));
    if (this.isEditActivity || this.isDuplicateActivity) {
      this.pageLoader.addRequiredComponent();
      this.fetchEditActivityInfo();
    }
    this.prepopulateDates();
    this.fetchProjectRoomSetups();
    this.fetchProjectSegmentations();
    this.setVideoOptions();
  },

  methods: {
    checkURL() {
      let string = this.$v.form.link.$model;
      if (string.match(/^https?:/) == null) {
        string = "https://" + string;
      }
      this.$v.form.link.$model = string;
    },
    getEndsOnBefore(end_date) {
      const before_enddate = moment(end_date)
        .subtract(1, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");

      return before_enddate;
    },
    getSystemActivityTypeIcon(systemActivityType) {
      return SystemIconHelper.activityType(systemActivityType);
    },

    fetchEditActivityInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: this.$route.params.activity,
        params: {
          projectSegments: "with",
          activityExternal: "with",
          stimulusAssets: "with",
          activitySettings: "with",
          appendSignedUrls: 1
        }
      };

      this.$store
        .dispatch(POPULATE_ACTIVITY_INFO, payload)
        .then(() => {
          this.setEditActivityInfo();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    setEditActivityInfo() {
      this.form.ends_on = this.activityInfo.ends_on;
      this.form.featured = this.activityInfo.featured;
      this.form.link = this.activityInfo.activity_external.link;
      this.form.popout = this.activityInfo.activity_external.popout;
      this.form.link_id = this.activityInfo.activity_external.id;
      this.form.project_room_id = this.activityInfo.project_room_id;
      this.form.published = this.activityInfo.published;
      this.form.starts_on = this.activityInfo.starts_on;
      this.form.title = this.activityInfo.title;
      this.form.summary = this.activityInfo.summary;

      this.form.segments = [];
      if (this.activityInfo.project_segments.length > 0) {
        this.activityInfo.project_segments.forEach(segment => {
          let seg = { parent: segment.project_segmentation_id, id: segment.id };
          this.initial_selection.push(seg);
        });
      }

      if (this.activityInfo.activity_settings.length > 0) {
        this.activityInfo.activity_settings.forEach((setting) => {
          let type = setting.pivot.system_activity_setting_id;
          let videoTypes = [5,6,7];
          var value = null;
          if (type == 2 || videoTypes.includes(type)) {
            value = parseInt(setting.pivot.value);
          } else {
            value = setting.pivot.value;
          }
          if (type == 1) {
            this.pre_populate_toggle = 1;
          }
          this.form.settings[
            setting.pivot.system_activity_setting_id
          ].data.value = value;
        });
      }

      this.pageLoader.setCompleteComponent();
    },
    fetchProjectSegmentations() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectSegments: "with",
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
    },
    fetchProjectRoomSetups() {
      let payload = {
        projectId: this.projectInfo.id,
        data: {
          params: {
            projectRooms: "with"
          }
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_ROOM_SETUP_LIST, payload)
        .then(() => {
          if (this.projectRoomSetupList.length > 0) {
            if (this.projectRoomSetupList[0].project_rooms.length > 0) {
              this.projectRoomSetupList[0].project_rooms.unshift({
                id: null,
                title: "None"
              })
            }
          }
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project room setups list, please check back later or contact the helpdesk";
        });
    },
    setVideoOptions() {
      if (this.isScreenRecord) {
        this.form.settings[6].data.value = 1;
      };
    },
    getPayload() {
      let payload = {
        projectId: this.projectInfo.id,
        data: this.form
      };
      if (this.isEditActivity) {
        payload.activityId = this.$route.params.activity;
      }

      if (this.isDuplicateActivity) {
        payload.data.duplicate_assets = this.activityInfo.stimulus_assets;
      }

      return payload;
    },
    onSubmit() {
      this.clearErrors();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.submissionStates.submitting = true;

      let payload = this.getPayload();

      this.$store
        .dispatch(
          this.isEditActivity
            ? UPDATE_PROJECT_ACTIVITY
            : CREATE_PROJECT_ACTIVITY,
          payload
        )
        .then(() => {
          this.makeToast("success");
          this.$router.push({ name: "project_activities" });
        })
        .finally(() => {
          this.submissionStates.submitting = false;
          this.$v.form.$reset();
        });
    },
    clearData: function() {
      this.$store.dispatch(CLEAR_ACTIVITY_INFO)
    },
    makeToast(variant = null) {
      if (this.isEditActivity) {
        this.toast.notify(this, this.form.title + ' ' + `${this.$t("TOASTS.EDIT_ACTIVITY.MESSAGE")}`, `${this.$t("TOASTS.EDIT_ACTIVITY.TITLE")}`, variant);
      } else {
        this.toast.notify(this, this.form.title + ' ' + `${this.$t("TOASTS.ADD_ACTIVITY.MESSAGE")}`, `${this.$t("TOASTS.ADD_ACTIVITY.TITLE")}`, variant);
      };
    },
    prepopulateDates() {
      if (this.isSequencedActivity == false) {
        this.form.starts_on = this.projectInfo.starts_on;
      } else {
        this.form.starts_on = this.activityInfo.starts_on;
      }

      this.form.ends_on = this.getEndsOnBefore(this.projectInfo.ends_on);
    },
    prepopulateParentRoom() {
      this.form.project_room_id = this.activityInfo.project_room_id;
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_ACTIVITY_ERRORS);
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectSegmentations",
      "projectRoomSetupList",
      "activityErrors",
      "activityInfo",
      "systemActivityTypes"
    ]),
    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },
    assetReservationData: function() {
      let data = {
        scope: "activity",
        data: {
          directory: "stimuli",
          projectId: this.projectInfo.id,
          new: 1
        }
      };
      return data;
    },
    managementType: function() {
      if (this.isEditActivity) {
        return "update";
      } else if (this.isDuplicateActivity) {
        return "duplicate";
      } else {
        return "post"
      }
    },
    calculatedStatus: function() {
      if (this.managementType == "update" && this.loadingComplete == true) {
        switch (this.activityInfo.system_activity_stage_id) {
          case 1:
            return "draft";
          case 2:
            return "published";
          case 3:
            return "active";
          case 4:
            return "closed";
          default:
            return null; 
        }
      } else {
        return null;
      }
    },
    tab2Errors: function() {
      if (
        this.$v.form.published.$anyError ||
        this.$v.form.featured.$anyError ||
        this.$v.form.project_room_id.$anyError ||
        this.$v.form.starts_on.$anyError ||
        this.$v.form.ends_on.$anyError
      ) {
        return true;
      } else {
        return false;
      }
    },
    tab1Errors: function() {
      if (
        this.$v.form.title.$anyError ||
        this.$v.form.summary.$anyError ||
        this.$v.form.link.$anyError ||
        this.$v.form.popout.$anyError
      ) {
        return true;
      } else {
        return false;
      }
    },
    pageType() {
      var routeName = this.$route.name;
    
      if (routeName.includes("_screen_recording")) {
        return routeName.replace("_screen_recording", "");
      } else {
        return routeName.replace("_external", "");
      };
    },
    isScreenRecord() {
      if (this.$route.name.includes("screen_recording")) {
        return true;
      } else {
        return false;
      };
    },
    isSequencedActivity: function() {
      return ManageActivityHelper.sequencedActivity(
        this.$route.params.activity,
        this.pageType
      );
    },
    isDuplicateActivity: function() {
      return ManageActivityHelper.duplicatingActivity(
        this.$route.params.activity,
        this.pageType
      );
    },
    sequenceableActivity: function() {
      return ManageActivityHelper.sequenceableActivity(
        this.systemActivityTypes,
        this.form.system_activity_type_id
      );
    },
    isScheduledActivity: function() {
      return ManageActivityHelper.scheduledActivity(
        this.isSequencedActivity,
        this.sequence_option_id
      );
    },
    isEditActivity: function() {
      return ManageActivityHelper.editingActivity(
        this.$route.params.activity,
        this.pageType
      );
    },
    computedValidation: function() {
      return this.$v.form;
    },
    computedTabs: function() {
      if (this.loadingComplete) {
        return TopformConfig.topform(this);
      } else {
        return [];
      }
    }
  }
};
</script>
