class ManageExternal {

  //Suggests a key based on either a client or project name
  static topform(component) {
      return [
        {
          id: 1,
          name: `${component.$t("FORMS.INPUTS.ACTIVITY_DETAILS")}`,
          errors: component.tab1Errors,
          sections: [
            {
              rows: [
                {
                  components: [
                    {
                      name: "InputActivityTitle",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-activity-title',
                        model: component.$v.form.title,
                        submissionStates: component.submissionStates
                      }
                    }
                  ]
                },
                {
                  components: [
                    {
                      name: "InputGenericTextArea",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-activity-summary',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_SUMMARY")}`,
                        model: component.$v.form.summary,
                        submissionStates: component.submissionStates
                      }
                    }
                  ]
                },
                {
                  components: [
                    {
                      name: "InputGeneric",
                      on: {
                        on_blur: (value) => { component.$refs["external-topform"].$emit('on_blur', value) }
                      },
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        type: "url",
                        showInline: false,
                        id: 'input-activity-link',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_URL")}`,
                        helper: `${component.$t("FORMS.HELPER.URL")}`,
                        model: component.$v.form.link,
                        submissionStates: component.submissionStates,
                        error: `${component.$t("FORMS.ERROR.URL")}`,
                      }
                    }
                  ]
                },
                {
                  show: component.isScreenRecord == false,
                  components: [
                    {
                      name: "InputGenericRadioOption",
                      properties: {
                        class: "col-xl-4 px-5 mb-0",
                        id: 'input-activity-popout',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_URL_POPUP")}`,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_URL_POPUP")}`,
                        model: component.$v.form.popout,
                        submissionStates: component.submissionStates,
                        options: [
                          {
                            value: 0,
                            text: `${component.$t('FORMS.INPUTS.ACTIVITY_POPUP')}`,
                            tooltip: `${component.$t('ACTIVITY.TOOLTIPS.ACTIVITY_POPUP')}`
                          },
                          {
                            value: 1,
                            text: `${component.$t('FORMS.INPUTS.ACTIVITY_NEW_TAB')}`,
                            tooltip: `${component.$t('ACTIVITY.TOOLTIPS.ACTIVITY_NEW_TAB')}`
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: `${component.$t("FORMS.SECTION_HEADERS.ACTIVITY_STIMULI")}`,
              rows: [
                {
                  components: [
                    {
                      name: "IntegratedDropzone",
                      on: {
                        asset_reservation_created: (value) => { component.$refs["external-topform"].$emit('asset_reservation_created', value) },
                        asset_reservation_deleted: (value) => { component.$refs["external-topform"].$emit('asset_reservation_deleted', value) },
                        remove_project_asset: (value) => { component.$refs["external-topform"].$emit('remove_project_asset', value) },
                      },
                      properties: {
                        class: "col-xl-12 px-3 mb-0",
                        assetReservation: component.assetReservationData,
                        idx: 1,
                        projectAssets: component.activityInfo.stimulus_assets,
                        wholeWidth: true,
                        removable: true
                      }
                    }
                  ]
                }
              ]
            },
          ]
        },
        {
          id: 2,
          name: `${component.$t("FORMS.INPUTS.ACTIVITY_SETTINGS")}`,
          errors: component.tab2Errors,
          sections: [
            {
              rows: [
                {
                  components: [
                    {
                      name: "InputGenericCheckbox",
                      properties: {
                        class: "col-xl-3 px-5 mb-0",
                        id: 'input-publish-toggle',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_PUBLISH")}`,
                        model: component.$v.form.published,
                        submissionStates: component.submissionStates
                      }
                    },
                    {
                      name: "InputGenericCheckbox",
                      properties: {
                        class: "col-xl-3 px-5 mb-0",
                        id: 'input-feature-toggle',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_FEATURED")}`,
                        model: component.$v.form.featured,
                        submissionStates: component.submissionStates
                      }
                    }
                  ]
                },
                {
                  show: component.projectRoomSetupList.length > 0,
                  components: [
                    {
                      show: (component.isSequencedActivity == false &&
                            component.projectRoomSetupList.length > 0)
                            ? 
                            component.projectRoomSetupList[0].project_rooms.length > 0 : false,
                      name: "InputGenericDropdown",
                      properties: {
                        class: "col-xl-4 px-5 mb-0",
                        id: 'input-room',
                        options: component.projectRoomSetupList.length > 0 ? component.projectRoomSetupList[0].project_rooms : null,
                        optionLabel: "title",
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_ROOM")}`,
                        model: component.$v.form.project_room_id,
                        errorMessage: `${component.$t("FORMS.ERROR.ACTIVITY_ROOM")}`,
                      }
                    }
                  ]
                }
              ]
            },
            {
              show: component.projectInfo.isTemplate == false,
              title: `${component.$t("FORMS.SECTION_HEADERS.ACTIVITY_DATES")}`,
              rows: [
                {
                  components: [
                    {
                      name: "InputGenericDatePicker",
                      on: {
                        date_updated: (value) => { component.$refs["external-topform"].$emit('start_date_updated', value) },
                      },
                      properties: {
                        class: "col-xl-4 px-5 mb-0",
                        id: "activity-start-date",
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_START_DATE")}`,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_START_DATE")}`,
                        type: "datetime",
                        notBefore: component.projectInfo.starts_on,
                        notAfter: component.projectInfo.ends_on,
                        model: component.$v.form.starts_on,
                        submissionStates: component.submissionStates
                      }
                    },
                    {
                      name: "InputGenericDatePicker",
                      on: {
                        date_updated: (value) => { component.$refs["external-topform"].$emit('end_date_updated', value) },
                      },
                      properties: {
                        class: "col-xl-4 px-5 mb-0",
                        id: "activity-end-date",
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_END_DATE")}`,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_END_DATE")}`,
                        type: "datetime",
                        notBefore: component.projectInfo.starts_on,
                        notAfter: component.projectInfo.ends_on,
                        model: component.$v.form.ends_on,
                        submissionStates: component.submissionStates
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }

  export default ManageExternal;